import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Reveal from 'reveal.js';
import 'reveal.js/dist/reveal.css';
import {
  duplicateSlideCanvas,
  initFabricEvents,
  initializeFabricCanvasInstance,
  loadGoogleFonts, setupAudioList,
  setupImageList,
  setupPexelsList,
  setupPexelsVideoList, setupRecordedAudioList,
  setupSlideList,
  setupVideoList, stopAllVideos,
  addImageToCanvas,
  createNewSlide,
  configureCanvasSize
} from "../../utils/helpers";
import '../../utils/Objects/StaticText';
import '../../utils/Objects/StaticImage';
import '../../utils/Objects/StaticButton';
import '../../utils/Objects/StaticInput';
import '../../utils/Objects/StaticCard';
import { useDispatch, useSelector } from "react-redux";
import { setActiveSlide, setActiveSlideIndex } from "../../store/reducers/slideListSlice";
import SlidesViewer from "../SlidesViewer/SlidesViewer";
import { setActiveCanvas, setContext, setCtxCoords } from "../../store/reducers/canvasSlice";
import LoaderPopup from "../LoaderPopup";
import { loadSvgs } from "../../utils/svgHelper";
import { CanvasActions } from "../../utils/canvasActions";
import { fabric } from "fabric";
import CropperPanel from "../CropperPanel/CropperPanel";
import { useAuth } from '../../context/AuthContext';
import { useLocation } from 'react-router';
import { useAlert } from "../../context/AlertContext";
//import AudioWaveform from "../AudioWaveform";


fabric.Object.NUM_FRACTION_DIGITS = 17;

function RevealSlide({ zoomLevel, onZoomChange, selectedResolution, isPreviewMode }) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { showAlert } = useAlert();
  const { slideList, activeSlideIndex, activeSlide, newSlideAdded } = useSelector(state => state.slideList);
  const { clipBoard, activeObject, canvas } = useSelector(state => state.fabricCanvas);
  const { show: showCropper } = useSelector(state => state.cropper);
  const canvasRef = useRef(null);
  const location = useLocation();
  const projectId = useMemo(() => location.pathname.split('/').pop() || '', [location]);
  const actions = useRef(null)


  // This useEffect ensures that the CanvasActions instance is created only when necessary. 
  // If the current activeSlide changes, a new CanvasActions instance is initialized to handle 
  // the new slide's canvas. This prevents the actions from being reinitialized unnecessarily, 
  // which could result in the loss of the undoStack and other state information. 
  // By checking if actions.current already exists and matches the current slide's canvas, 
  // we maintain the persistence of the current actions while ensuring proper initialization for new slides.
  useEffect(() => {
    if (activeSlide) {
      if (!actions.current || actions.current.canvas !== activeSlide.canvas) {
        actions.current = new CanvasActions({ activeSlide, dispatch, clipBoard });
      }
    }
  }, [activeSlide, dispatch, clipBoard]);

  useEffect(() => {
    const handleChange = (e) => {
      actions.current.saveState()
    }

    canvas?.on('object:added', handleChange);
    canvas?.on('object:removed', handleChange);
    canvas?.on('object:modified', handleChange);
    canvas?.on('selection:created', handleChange);
    canvas?.on('selection:cleared', handleChange);

    return () => {
      canvas?.off('object:added', handleChange);
      canvas?.off('object:removed', handleChange);
      canvas?.off('object:modified', handleChange);
      canvas?.off('selection:created', handleChange);
      canvas?.off('selection:cleared', handleChange);
    };

  }, [canvas, dispatch, actions?.current?.saveState])

  useEffect(() => {

    const handleDrop = (e) => {
      try {
        const data = e.e.dataTransfer.getData("text/plain");
        const image = JSON.parse(data)
        if (image) {
          addImageToCanvas(image, dispatch, canvas, activeSlide?.id)
        }
      } catch (error) {
        return
      }
    }

    canvas?.on('drop', handleDrop)

    return () => {
      canvas?.off('drop', handleDrop)
    };
  }, [activeSlide, canvas, dispatch])


  const slideChangeHandler = useCallback((event) => {
    const previousSlide = window.slideList[activeSlideIndex];
    const newCanvasInstance = window.slideList[event.indexh].canvas;
    previousSlide && previousSlide.canvas.discardActiveObject().renderAll();
    previousSlide && stopAllVideos(previousSlide.canvas);
    window.fabricCanvas = newCanvasInstance;
    dispatch(setActiveCanvas(newCanvasInstance));
    dispatch(setActiveSlide(window.slideList[event.indexh]));
    dispatch(setActiveSlideIndex(event.indexh));
  }, [dispatch, activeSlideIndex]);

  useEffect(() => {
    Reveal.on('slidechanged', slideChangeHandler);

    return () => {
      Reveal.off('slidechanged', slideChangeHandler);
    };
  }, [slideChangeHandler]);

  useEffect(() => {
    if (user?.user_id && projectId) {
      setupImageList(dispatch, user?.user_id);
      setupPexelsVideoList(dispatch);
      setupPexelsList(dispatch);
      setupVideoList(dispatch, user?.user_id);
      loadSvgs(dispatch);
      loadGoogleFonts(dispatch);
      setupAudioList(dispatch, user?.user_id);
      setupRecordedAudioList(dispatch, user?.user_id);
      setupSlideList(dispatch, projectId).then(({ objectURLs }) => {
        // If no slides are available, create a new slide by default to avoid errors when trying to add elements
        // to a non-existent canvas, this issue should be solved on strapi side on future
        if (objectURLs.length === 0) {
          createNewSlide(dispatch, slideList.length, projectId, showAlert, false)
          setActiveSlideIndex(0)
          setActiveSlide(slideList[0])
        }
      })
    }
    // eslint-disable-next-line
  }, [user, projectId]);

  useEffect(() => {
    if (slideList.length !== 1) {
      const latestSlide = slideList[slideList.length - 1];
      if (latestSlide && !latestSlide.canvas) {
        initializeFabricCanvasInstance(latestSlide, slideList.length, canvasRef, selectedResolution);
        if (latestSlide.hasOwnProperty('isDuplicate')) {
          duplicateSlideCanvas(latestSlide).then(() => {
            initFabricEvents(latestSlide.canvas, dispatch);
          });
        } else {
          initFabricEvents(latestSlide.canvas, dispatch, true);
        }
      }
    }
    // eslint-disable-next-line
  }, [newSlideAdded]);

  useEffect(() => {
    //let canvasZoom = 0;
    slideList.forEach((slide, index) => {

      if (!slide.canvas) {
        initializeFabricCanvasInstance(slide, index, canvasRef, selectedResolution);
        initFabricEvents(slide.canvas, dispatch);
        if (index === 0) {
          dispatch(setActiveCanvas(slide.canvas));
          dispatch(setActiveSlide(slide));
          //canvasZoom = slide.canvas.getZoom();
          //console.log("canvasZoom", canvasZoom);
        }
      }

    });

    window.slideList = slideList;

    const handleGlobalClick = (e) => {
      if (e.button === 0) {
        // Left-click outside the canvas, hide the context menu
        dispatch(setContext(false));
      }
    };

    Reveal.initialize({
      // Reveal.js options
      hash: true,
      width: configureCanvasSize(selectedResolution, canvasRef).width,
      height: configureCanvasSize(selectedResolution, canvasRef).height,
      controls: false,
      progress: true,
      slideNumber: true,
      jumpToSlide: false,
      scrollActivationWidth: null,
      touch: false,
      transition: 'none',
      keyboard: {
        27: null,  // Disables 'ESC' key for overview mode
        79: null   // Disables 'O' key for overview mode
      },
    });
    // Add a global click event listener to the document
    document.addEventListener('click', handleGlobalClick);
    // disable default context menu every where

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleGlobalClick);
    };

    // eslint-disable-next-line
  }, [slideList]);

  useEffect(() => {
    document.addEventListener('keydown', keyboardEvents);
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener('keydown', keyboardEvents);
      document.removeEventListener("contextmenu", handleContextMenu)
    }
    // eslint-disable-next-line
  }, [canvas, activeObject, clipBoard, activeSlide])

  const handleContextMenu = (event) => {
    if (canvas) {
      const { pageTop, pageLeft } = window.visualViewport;
      const { clientX, clientY } = event;
      const { top: canvasOffsetTop, left: canvasOffsetLeft } = canvas._offset;
      if (
        clientX < canvasOffsetLeft ||
        clientY < canvasOffsetTop ||
        clientX > canvasOffsetLeft + canvas.width ||
        clientY > canvasOffsetTop + canvas.height
      ) {
        return;
      }
      event.preventDefault();
      dispatch(setCtxCoords({
        left: clientX + pageLeft,
        top: clientY + pageTop
      }));
      dispatch(setContext(true));
      const clickPoint = new fabric.Point(event.offsetX, event.offsetY);
      const objectList = [];

      canvas.forEachObject((obj) => {
        if (obj.containsPoint(clickPoint) && !obj.hasOwnProperty('isBoundingRect')) {
          objectList.push(obj);
        }
      });

      if (objectList.length > 0) {
        canvas.setActiveObject(objectList[objectList.length - 1]);
        canvas.renderAll();
      }
    }
  }

  const updateCanvasSize = useCallback(() => {
    const container = canvasRef.current;
    if (container && canvas && activeSlide) {
      //   const aspectRatio = 16 / 9;  // Maintain aspect ratio of 16:9
      const containerWidth = container.offsetWidth; // Use offsetWidth to get width
      // Calculate new canvas dimensions maintaining aspect ratio
      const canvasWidth = containerWidth * (zoomLevel / 100);
      const canvasHeight = canvasWidth / 16 * 9;
      canvas.setWidth(canvasWidth);
      canvas.setHeight(canvasHeight);
      canvas.setWidth(canvasWidth);
      canvas.setZoom(zoomLevel / 100);
      canvas.renderAll();
    }
  }, [canvas, activeSlide, zoomLevel]);

  useEffect(() => {
    window.addEventListener('resize', updateCanvasSize);
    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [updateCanvasSize]);

  useEffect(() => {
    if (zoomLevel) {
      updateCanvasSize();
    }
  }, [zoomLevel, updateCanvasSize]);



  const keyboardEvents = (e) => {
    if (e.keyCode === 46) {
      //Delete
      e.preventDefault();
      actions.current.delete();
    } else if (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) {
      //Copy
      e.preventDefault();
      actions.current.cutCopy();
    } else if (e.keyCode === 68 && (e.ctrlKey || e.metaKey)) {
      //Duplicate
      e.preventDefault();
      actions.current.duplicate();
    } else if (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) {
      // Paste
      e.preventDefault();
      actions.current.paste();
    } else if (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) {
      // Cut
      e.preventDefault();
      actions.current.cutCopy(true);
    } else if (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) {
      // select all
      e.preventDefault();
      actions.current.selectAll()
    } else if (e.keyCode === 90 && (e.ctrlKey || e.metaKey)) {
      // Undo
      e.preventDefault();
      actions.current.undo();
    }
  }


  return (
    <div className="relative reveal flex flex-col items-center justify-center bg-slate-300">
      {showCropper &&
        (
          <>
            <CropperPanel />
            <div className="fixed top-0 left-0 w-full h-full bg-transparent opacity-50 z-5000 " />
          </>
        )}
      <div className="flex relative !h-full !w-full">
        <div className="scrollbar-track-transparent slides flex !inset-0 !mt-1 !transform-none translate-y-0 items-center z-6000 !pointer-events-auto" ref={canvasRef}>

          {slideList && slideList.length > 0 ? (
            slideList.map((slide) => (
              <section key={slide.id} style={{ opacity: slide?.id === activeSlide?.id ? 1 : 0 }}>
                <canvas id={`slide_deck_canvas_${slide.id}`} />
              </section>
            ))
          ) : (
            // Modal for empty slide list
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded shadow-lg w-96">
                <h2 className="text-lg font-semibold text-gray-700 text-center mb-4">No Slides Available</h2>
                <p className="text-center text-gray-600">Create slide first before next steps</p>
                <button
                  className="mt-4 w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
                  onClick={() => createNewSlide(dispatch, slideList.length, projectId, showAlert, false)}
                >
                  Create Slide
                </button>
              </div>
            </div>
          )}

        </div>
      </div>

      <div className="flex bottom-0 z-10 shrink-0 w-full relative">
        <ErrorBoundary>
          <SlidesViewer isPreviewMode={isPreviewMode} />
        </ErrorBoundary>
      </div>
      <LoaderPopup />
    </div>
  );
}

export default RevealSlide;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error("Error caught in SlidesViewer:", error, info);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong in SlidesViewer.</h1>;
    }

    return this.props.children;
  }
}