import React, { useMemo, useRef, useState } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TrueFalseQuestionsForm from "./TrueFalseQuestionsForm";
import TrueFalseQuestionPreview from "./TrueFalseQuestionPreview";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import {
  centerFabricObject,
  getFabricImageFromURL,
  handleLoader,
  S3_BUCKET_NAME,
  uploadToS3,
} from "../../../../utils/helpers";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { setAllMediaToSlide } from "../../../../store/reducers/slideListSlice";
import SingleSelectionForm from "./SingleSelectionForm";
import SingleSelectionPreview from "./SingleSelectionPreview";
import { isNumber } from "lodash";
import MultiSelectionForm from "./MultiSelectionForm";
import MultiSelectionPreview from "./MultiSelectionPreview";
import { useAuth } from "../../../../context/AuthContext";

const questionTypes = [
  { value: "true-false", label: "True/False Question" },
  { value: "single-selection", label: "Single Selection Question" },
  { value: "multiple-selection", label: "Multiple Selection Question" },
];

const totalSteps = 3;

const QuestionBankModal = ({ isOpen, closeModal }) => {
  const { user } = useAuth();
  const { canvas } = useSelector((state) => state.fabricCanvas);
  const { activeSlide } = useSelector((state) => state.slideList, shallowEqual);
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [questionType, setQuestionType] = useState("true-false");
  const [formData, setFormData] = useState(null);
  const previewRef = useRef(null);

  const handleNextStep = () => {
    setActiveStep((prevStep) =>
      prevStep === totalSteps - 1 ? prevStep : prevStep + 1
    );
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => (prevStep === 0 ? prevStep : prevStep - 1));
  };
  const handleClose = () => {
    setActiveStep(0);
    setFormData(null);
    setQuestionType("true-false");
    closeModal();
  };

  const currentQuestionType = useMemo(
    () => questionTypes.find((i) => i.value === questionType),
    [questionType]
  );

  const hasResultInSlide = useMemo(
    () => activeSlide?.canvas?.getObjects()?.filter(i => ['result'].includes(i.type))?.length > 0,
    [activeSlide]
  );

  const handleAddImage = (image) => {
    handleLoader(true, "load_image_on_canvas", dispatch);
    const imageId = uuidv4();
    getFabricImageFromURL(
      image.url,
      (fabricImage) => {
        fabricImage.set({
          id: imageId,
          borderWidth: 0,
          borderStyle: "none",
          borderFill: "rgb(0,0,0)",
          listStyle: "none",
          link: "",
          jumpToSlide: 0,
          slideId: activeSlide?.id,
          height: canvas.height,
          width: canvas.width,
          type: "quiz",
          formData,
          questionType,
        });
        fabricImage.setControlsVisibility({
          mt: false,
          mb: false,
        });
        fabricImage.scaleToWidth(canvas.width);
        fabricImage.scaleToHeight(canvas.height);
        centerFabricObject(fabricImage, canvas);
        canvas.add(fabricImage);
        canvas.setActiveObject(fabricImage);
        canvas.renderAll();
        dispatch(
          setAllMediaToSlide({
            type: "quiz",
            data: {
              ...image,
              id: imageId,
              question: formData.question || formData.title,
            },
          })
        );
        handleLoader(false, "", dispatch);
        handleClose();
      },
      () => {
        handleLoader(false, "", dispatch);
      }
    );
  };

  const addPreviewToslide = async () => {
    if (hasResultInSlide) {
      return;
    }

    try {
      const element = previewRef.current;
      const canvas = await html2canvas(element);
      const base64Data = canvas.toDataURL("image/jpeg");

      const base64Response = await fetch(base64Data);
      const blob = await base64Response.blob();

      const fileName = `${user?.user_id}/image_list/user_upload_${uuidv4()}.jpeg`;
      const params = {
        Bucket: S3_BUCKET_NAME,
        Key: fileName,
        Body: blob,
        ContentType: 'image/jpeg',
      };

      const s3Url = await uploadToS3(blob, fileName, params, dispatch);

      handleAddImage({ url: s3Url });
    } catch (error) {
      console.error("Error while adding image to slide", error);
    }
  };

  const renderPreview = useMemo(() => {
    switch (questionType) {
      case "true-false":
        return (
          <TrueFalseQuestionPreview data={formData} previewRef={previewRef} />
        );
      case "single-selection":
        return (
          <SingleSelectionPreview data={formData} previewRef={previewRef} />
        );
      case "multiple-selection":
        return (
          <MultiSelectionPreview data={formData} previewRef={previewRef} />
        );
      default:
        return null;
    }
  }, [questionType, formData, previewRef]);

  const renderForm = useMemo(() => {
    switch (questionType) {
      case "true-false":
        return <TrueFalseQuestionsForm data={formData} setData={setFormData} />;
      case "single-selection":
        return <SingleSelectionForm data={formData} setData={setFormData} />;
      case "multiple-selection":
        return <MultiSelectionForm data={formData} setData={setFormData} />;
      default:
        return null;
    }
  }, [questionType, setFormData, formData]);

  const disabledAddToSlide = useMemo(
    () =>
      (questionType === "true-false" && !formData?.question) ||
      (!(formData?.title && isNumber(formData?.correctAnswer)) &&
        questionType === "single-selection") ||
      (!(formData?.title && formData?.correctAnswer?.length) &&
        questionType === "multiple-selection"),
    [formData, questionType]
  );
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative mx-auto p-5 border w-[540px] shadow-lg rounded-[15px] bg-white">
        <div className="flex items-center justify-between  border-b pb-4">
          <h3 className="text-[16px] leading-6 text-gray-900 font-semibold">
            Question Bank - Step {activeStep + 1}{" "}
            {activeStep !== 0 ? currentQuestionType.label : ""}
          </h3>
          <button onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} className="size-4 text-gray-400" />
          </button>
        </div>
        <div className="my-5">
          {activeStep === 0 ? (
            <div>
              <label
                htmlFor="fontSelector"
                className="mb-2 block text-[16px] font-medium text-gray-600"
              >
                Select type of Question to add to your slide
              </label>
              <div className="my-4 flex flex-col gap-[15px]">
                {questionTypes?.map((type, index) => (
                  <div
                    className="flex items-center"
                    key={`${type.value}-${index}`}
                  >
                    <input
                      id={type.value}
                      name={type.value}
                      type="radio"
                      value={type.value}
                      checked={questionType === type.value}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer"
                      onChange={(e) => {
                        setQuestionType(e.target.value);
                        setFormData(null);
                      }}
                    />
                    <label
                      htmlFor={type.value}
                      className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300  cursor-pointer"
                    >
                      {type.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {activeStep === 1 ? (
            <>
              {renderForm}
              <div className="flex items-center my-4">
                <input
                  id={`${questionType}-addToScoring`}
                  name="addToScoring"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer"
                  checked={!!formData?.addToScoring}
                  value={!!formData?.addToScoring}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      addToScoring: e.target.checked,
                    }));
                  }}
                />
                <label
                  htmlFor={`${questionType}-addToScoring`}
                  className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                >
                  Add this question to final scoring?
                </label>
              </div>
            </>
          ) : null}
          {activeStep === totalSteps - 1 ? renderPreview : null}
        </div>
        <div className="flex items-center justify-end  border-t pt-4 gap-[20px]">
          <button
            onClick={activeStep === 0 ? handleClose : handlePrevStep}
            className="text-gray-400 text-[14px]"
          >
            {activeStep === 0 ? "Cancel" : "Previous"}
          </button>
          <button
            onClick={
              activeStep === totalSteps - 1 ? addPreviewToslide : handleNextStep
            }
            disabled={activeStep === totalSteps - 1 && disabledAddToSlide}
            className={`${activeStep === totalSteps - 1
                ? "bg-amber-400 hover:bg-amber-500"
                : "bg-blue-500 hover:bg-blue-700"
              } text-white py-2 px-4 rounded-full text-[14px] focus:outline-none ${activeStep === totalSteps - 1 && disabledAddToSlide
                ? "opacity-50"
                : ""
              }`}
          >
            {activeStep === totalSteps - 1 ? "Add To Slide" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionBankModal;
