import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { shallowEqual } from 'react-redux';
import { setAllMediaToSlide, updateAllMediaToSlide } from '../../../store/reducers/slideListSlice';
import { useDispatch } from 'react-redux';
import ImageGallery from '../ImagePanel/ImageGallery';
import PexelsGallery from '../ImagePanel/PexelsGallery';
import { handleLoader } from '../../../utils/helpers';
import { SketchPicker } from "react-color";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faEyeDropper, faSquare } from '@fortawesome/free-solid-svg-icons';

//import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const Card = () => {
  const { canvas, activeObject } = useSelector((state) => state.fabricCanvas);
  const { activeSlide } = useSelector(state => state.slideList, shallowEqual);
  const [selectedCardImg, setSelectedCardImg] = useState('');
  const [cardText, setCardText] = useState('Card Title');
  const [cardTextLong, setCardTextLong] = useState('Card Text');
  const [cardBgColor, setCardBgColor] = useState('#ffffff');
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false); const [selectedTab, setSelectedTab] = useState('uploads');
  const dispatch = useDispatch()
  const colorPickerRef = useRef(null);
  const isActiveObject = useMemo(() => !!(activeObject?.type === 'card' && activeObject), [activeObject])

  const toggleColorPicker = () => {
    setIsColorPickerOpen((prevIsColorPickerOpen) => !prevIsColorPickerOpen);
  };

  const handleClickOutside = (event) => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
      setIsColorPickerOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener on mount
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); // Empty dependency array means this effect runs only once, on mount

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const canvasCardStyles = {
    fill: cardBgColor,
    fontWeight: 'bold',
    cardType: 'standard',
  };

  const handleCardLoading = (visibility) => {
    handleLoader(visibility, 'load_card_on_canvas', dispatch);
  }


  const updateActiveObject = (updatedData, activeObject) => {
    const card = new fabric.StaticCard({
      id: activeObject.id,
      label: activeObject.label,
      labelLong: activeObject.labelLong,
      fill: activeObject.fill,
      color: activeObject.color,
      fontWeight: activeObject.fontWeight,
      cardType: activeObject.cardType,
      type: 'card',
      left: activeObject.left,
      top: activeObject.top,
      cardImg: activeObject.cardImg,
      slideId: activeObject?.slideId,
      handleCardLoading,
      ...updatedData,
    }, canvas);
    dispatch(updateAllMediaToSlide({ type: 'card', data: { label: updatedData.label, id: activeObject.id } }));
    canvas.remove(activeObject); // Remove the existing active object from the canvas
    canvas.add(card); // Add the updated active object to the canvas
    canvas.renderAll();
    return;
  }


  // Add element to slide
  const handleAddUpdateElement = () => {
    // Assuming canvas.lowerCanvasEl.id dynamically identifies the target div
    const targetSlides = document.getElementsByClassName('canvas-container');
    if (targetSlides.length === 0 || !canvas) {
      console.error('Target slide not found');
      return;
    }
    // cardEl.className = selectedStyle ? cardStyles[selectedStyle] : '';
    // Ensure there is always a valid text for the card
    const cardTextValue = cardText || 'Card Title'; // Default to 'Card' if cardText is falsy
    const cardTextValueLong = cardTextLong || 'Card Text'; // Default to 'Card' if cardText is falsy
    // Create a rectangle to simulate the card background
    const {
      fill,
      fontWeight,
      cardType = '',
    } = canvasCardStyles;
    if (isActiveObject) {
      handleCardLoading(true);
      updateActiveObject({
        label: cardTextValue,
        labelLong: cardTextValueLong,
        fill,
        cardImg: selectedCardImg,
      }, activeObject);
    } else {
      const id = uuidv4();
      dispatch(setAllMediaToSlide({ type: 'card', data: { label: cardTextValue, id } }));
      handleCardLoading(true);
      const card = new fabric.StaticCard({
        id,
        label: cardTextValue,
        labelLong: cardTextValueLong,
        fill, // Default fill color
        fontWeight,
        cardType,
        type: 'card',
        cardImg: selectedCardImg,
        slideId: activeSlide?.id,
        handleCardLoading,
      }, canvas);
      canvas.add(card);
      // Center the card
      if (selectedCardImg) {
        card.set({
          top: canvas.width / 2,
          left: canvas.height / 2,
        })
      }
      card.center();
      card.setCoords();
      canvas.renderAll();
    }
  };

  const handleAddImageToCard = (image) => {
    setSelectedCardImg(image?.url || '');
  }

  const handleBgFillChange = (color) => {
    setCardBgColor(color.hex);
  }

  const handleEyeDropperClick = () => {
    // eslint-disable-next-line no-undef
    const eyeDropper = new EyeDropper();
    eyeDropper.open().then((color) => {
      setCardBgColor(color.sRGBHex.replace(', 0', ', 1'));
    }).catch(() => {
      return
   });
  };

  useEffect(() => {
    if (activeObject && isActiveObject) {
      const {
        label,
        labelLong,
        fill,
        cardImg,
      } = activeObject;
      setCardBgColor(fill)
      setCardText(label);
      setCardTextLong(labelLong);
      setSelectedCardImg(cardImg)
    }
  }, [activeObject, isActiveObject]);

  return (
    <div className="mt-4">
      {isActiveObject && <label className="block text-lg font-semibold mb-5">Update Card</label>}
      <div className="mb-5"> {/* Changed marginBottom: '20px' to Tailwind's mb-5 */}
        <label className="block text-md font-semibold mb-2">Card Preview:</label> {/* Example of adding classes for the label */}
        <div className='rounded relative z-50 min-h-64 rounded-md shadow-lg' style={{ backgroundColor: cardBgColor }}>
          {selectedCardImg ? <img className='h-[160px] w-full object-cover rounded-t-lg' src={selectedCardImg} alt='' /> : <div className='h-[160px] w-full bg-slate-300 rounded-t-lg' />}
          <div className='p-2 text-black font-bold min-h-10'>
            <p className='mt-[10px] break-words mb-2 text-black font-bold'>{cardText}</p>
            <p className='mt-[10px] break-words pb-6 font-normal text-slate-500'>{cardTextLong}</p>
          </div>
        </div>
      </div>
      <div className="mb-4"> {/* Changed marginBottom: '10px' to Tailwind's mb-4 */}
        <label className="block text-lg font-semibold mb-2">Card Title:</label>
        <input type="text" value={cardText} onChange={(e) => setCardText(e.target.value)} className="input bg-white border border-gray-300 rounded py-2 px-4 block w-full focus:border-blue-500 focus:ring-blue-500" />
      </div>
      <div className="mb-4"> {/* Changed marginBottom: '10px' to Tailwind's mb-4 */}
        <label className="block text-lg font-semibold mb-2">Card Text:</label>
        <input type="text" value={cardTextLong} onChange={(e) => setCardTextLong(e.target.value)} className="input bg-white border border-gray-300 rounded py-2 px-4 block w-full focus:border-blue-500 focus:ring-blue-500" />
      </div>
      <div className="mb-4"> {/* Tailwind's mb-4 */}
        <label className="block text-lg font-semibold mb-1">Select Card Image:</label>
      </div>
      <div className="flex items-center justify-center p-2 bg-white rounded-md rounded-b-none border border-b-0">
        <div className={`flex w-full justify-center rounded-l-md p-2 border transition-all duration-200 active:bg-gray-200 border-r-0 ${selectedTab === 'uploads' ? 'bg-gray-100' : ''}`} onClick={() => handleTabClick('uploads')}>
          <label
            className={`text-xs rounded-md text-gray-600 cursor-pointer`}>Uploads</label>
        </div>
        <div className={`flex w-full justify-center rounded-r-md p-2 border transition-all duration-200 active:bg-gray-200 border-l-0 ${selectedTab === 'images' ? 'bg-gray-100' : ''}`} onClick={() => handleTabClick('images')}>
          <label
            className={`text-xs rounded-md text-gray-600 cursor-pointer`}>Images</label>
        </div>
      </div>
      {
        selectedTab === 'uploads' && (
          <ImageGallery
            handleAddImage={handleAddImageToCard}
            hideRemoveImg
          />
        )
      }
      {
        selectedTab === 'images' && (
          <PexelsGallery handleAddImage={handleAddImageToCard} hideRemoveImg />
        )
      }
      <div className="mb-4"> {/* Tailwind's mb-4 */}
        <div className='w-full mb-2'>
          <div ref={colorPickerRef} className={`relative flex size-10 cursor-pointer items-center w-fit justify-center rounded-md border text-xs transition-colors duration-200 ${isColorPickerOpen ? 'bg-gray-100' : 'bg-white'}`}>
            <button
              className="flex items-center justify-center px-3"
              onClick={toggleColorPicker}
            >
              <FontAwesomeIcon icon={faSquare} color={cardBgColor} className="mr-2 size-3 border" />
              {isColorPickerOpen && <FontAwesomeIcon icon={faAngleUp} className="size-3 text-gray-600" />}
              {!isColorPickerOpen && <FontAwesomeIcon icon={faAngleDown} className="size-3 text-gray-600" />}
            </button>
            {isColorPickerOpen && (
              <div className="absolute top-full left-0 mt-2 z-50">
                <SketchPicker color={cardBgColor} onChange={handleBgFillChange} width={175} />
              </div>
            )}
            <div className="h-10 flex items-center px-3 border-y border rounded-r-md" onClick={handleEyeDropperClick}>
              <FontAwesomeIcon icon={faEyeDropper} className="size-3" />
            </div>
          </div>
        </div>
        <div className="mb-4 py-4 cursor-pointer">
          {/* Insert the Add to Canvas card with the onClick handler */}
          <button onClick={handleAddUpdateElement} className='h-12 w-full rounded-md border bg-white transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200'>{isActiveObject ? 'Update Card' : 'Add to Canvas'}</button>
        </div>
      </div>
    </div>

  );
};

export default Card;