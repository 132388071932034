import React, { useEffect, useState } from "react";
import { api } from "../../api/api";
import { MoneySquare, ThumbsUp, Eye } from "iconoir-react";
import { useNavigate } from "react-router-dom";
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import useHandleCheckout from "../../utils/handleCheckout";



const IntraMarketPlace = () => {
const [projects, setProjects] = useState([]);
const handleCheckout = useHandleCheckout();
//const [setSearchResults] = useState([]);
const navigate = useNavigate();

    useEffect(() => {
        const fetchProjects = async () => {
          try {
            const response = await api.get(`/api/projects?filters[status][$eq]=public`);
            if (response.data) {
              setProjects(response.data); // Set local state
              //setSearchResults(response.data); // Local state for search results
            }
          } catch (error) {
            console.error("Error fetching projects:", error);
          }
        };
    
        fetchProjects();
      }, []); 

  return (
    <div className="flex h-screen bg-custom-skyBlue">
        <Sidebar />

        <div className="flex-1 flex flex-col">
          <TopBar />
          <main className="flex-1 bg-custom-skyBlue p-4 overflow-auto">
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-lg font-semibold mb-2">Market Place</h3>
            <div className="flex flex-wrap gap-4">
              {projects.map(project => (
              <div key={project.id} className="w-full max-w-xs bg-white rounded-lg shadow-lg p-6">
                  <img 
                    src={project.project_image?.url ? `https://backend.ailms.co${project.project_image.url}` : "/logo512.png"}
                    alt={project.name} 
                    className="mb-2 w-full h-48 object-cover rounded-lg" 
                  />
                  
                  <div className="flex justify-left mt-4 text-xxs">
                    <div className="flex items-left bg-[#e5fae4] py-1 px-2 rounded-lg">
                      <MoneySquare className="h-4 w-4 mr-1 text-gray-500" />
                      <div className="text-xxs font-bold mr-1 text-gray-500">{project.purchases || "XX"}</div>
                      <div className="text-xxs text-gray-500">Purchases</div>
                    </div>
                  </div>
                  <div className="text-left mt-4">
                    <div className="text-xl font-bold text-gray-900 mb-2">{project.name}</div>
                    <div className="text-gray-500 text-sm">Author | {project.user[0]?.username || "Unknown User"}</div>
                  </div>
                  <div className="mt-4">
                    <div className="text-sm text-gray-500">{project.category || "N/A"}</div>
                    <div className="flex flex-wrap gap-2 mt-2">
                      {project.tags && project.tags.length > 0 ? project.tags.map(tag => (
                        <span key={tag.id} className="bg-gray-200 hover:bg-gray-300 py-1 px-2 rounded-lg text-xxs">
                          {tag.tagName}
                        </span>
                      )) : "N/A"}
                    </div>
                  </div>
                  <div className="mt-6 flex justify-between items-end">
                  <button onClick={() => handleCheckout(project.project_id, project.price, project.user_id)} 
                    className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xxs font-medium text-gray-900 rounded-lg bg-custom-lightBlue hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300" style={{ minWidth: '100px' }}>
                    <span className="relative text-white px-6 text-xs font-bold py-1.5 transition-all ease-in duration-75 rounded-md group-hover:bg-opacity-0">
                      Buy ${project.price || "XX"} 
                    </span>
                  </button>
                  <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xxs font-medium text-gray-900 rounded-lg group bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300">
                    <span className="relative px-4 py-1.5 transition-all ease-in duration-75 rounded-md group-hover:bg-opacity-0">
                      <ThumbsUp className="text-xxs" />
                    </span>
                  </button>
                  <button
                    className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xxs font-medium text-gray-900 rounded-lg group bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300"
                    onClick={() => navigate(`/intra-marketplace/${project.project_id}`)}
                  >
                    <span className="relative px-4 py-1.5 transition-all ease-in duration-75 rounded-md group-hover:bg-opacity-0">
                      <Eye className="text-xxs" />
                    </span>
                  </button>
                  
                </div>
              </div>
              ))}
            </div>
          </div>
          </main>
        </div>
    </div>

  
  )
}

export default IntraMarketPlace;
