import React from 'react';

function TrueFalseQuestionsFrom({ setData, data }) {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    return (
        <div>
            <div className='my-2'>
                <label
                    htmlFor='question'
                    className='block mb-2 text-sm font-medium text-gray-900'
                >
                    Enter Your Question
                </label>
                <input
                    type='text'
                    id='question'
                    name='question'
                    value={data?.question || ''}
                    onChange={handleChange}
                    className='block w-full p-2 text-gray-900 border border-gray-300 rounded bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500'
                />
            </div>
            <div className='flex items-center justify-center my-5 gap-[10px] flex-wrap'>
                <button
                    type='button'
                    className='px-4 mr-0 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600'
                >
                    <input
                        type='text'
                        id='trueBtnText'
                        name='trueBtnText'
                        value={data?.trueBtnText || 'True'}
                        onChange={handleChange}
                        className='block w-full p-2 text-white text-center bg-inherit text-sm focus:outline-none'
                    />
                </button>
                <button
                    type='button'
                    className='px-4 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:bg-red-600'
                >
                    <input
                        type='text'
                        id='falseBtnText'
                        name='falseBtnText'
                        value={data?.falseBtnText || 'False'}
                        onChange={handleChange}
                        className='block w-full p-2 text-white text-center bg-inherit text-sm focus:outline-none'
                    />
                </button>
            </div>
            <div className='my-2'>
                <input
                    type='text'
                    id='trueAnsPropmt'
                    name='trueAnsPropmt'
                    value={data?.trueAnsPropmt || ''}
                    onChange={handleChange}
                    placeholder='Enter prompt for correct answer'
                    className='block w-full p-2 text-gray-900 border border-gray-300 rounded bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500'
                />
            </div>
            <div className='my-2'>
                <input
                    type='text'
                    id='falseAnsPropmt'
                    name='falseAnsPropmt'
                    value={data?.falseAnsPropmt || ''}
                    onChange={handleChange}
                    placeholder='Enter prompt for incorrect answer'
                    className='block w-full p-2 text-gray-900 border border-gray-300 rounded bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500'
                />
            </div>
        </div>
    );
}

export default TrueFalseQuestionsFrom;
