import React, { useMemo } from "react";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isArray } from "lodash";

const MultiSelectionForm = ({ data, setData }) => {
  const addOption = () => {
    setData({
      ...data,
      options: [...(data.options ?? []), { label: "" }]
    });
  };

  const removeOption = (optionIndex) => {
    const newOptions = data.options.filter((_, index) => index !== optionIndex);
    const newCorrectAnswer = isArray(data.correctAnswer) 
      ? data.correctAnswer
          .filter(index => index !== optionIndex)
          .map(index => index > optionIndex ? index - 1 : index)
      : [];

    setData({
      ...data,
      options: newOptions,
      correctAnswer: newCorrectAnswer
    });
  };

  const handleQuestionChange = (value) => {
    setData({
      ...data,
      title: value
    });
  };

  const handleOptionChange = (optionIndex, value) => {
    const newOptions = data.options.map((option, index) => 
      index === optionIndex ? { ...option, label: value } : option
    );
    
    const newCorrectAnswer = !value && isArray(data.correctAnswer)
      ? data.correctAnswer.filter(index => index !== optionIndex)
      : data.correctAnswer;

    setData({
      ...data,
      options: newOptions,
      correctAnswer: newCorrectAnswer
    });
  };

  const handleCorrectAnswerChange = (optionIndex) => {
    const currentAnswers = isArray(data.correctAnswer) ? data.correctAnswer : [];
    const newCorrectAnswer = currentAnswers.includes(optionIndex)
      ? currentAnswers.filter(index => index !== optionIndex)
      : [...currentAnswers, optionIndex];

    setData({
      ...data,
      correctAnswer: newCorrectAnswer
    });
  };

  const formDefaultData = useMemo(() => data, [data]);

  const correctAnswers = isArray(formDefaultData?.correctAnswer) 
    ? formDefaultData.correctAnswer 
    : [];

  return (
    <div>
      <div className="my-4">
        <label
          htmlFor="question"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Enter Your Question
        </label>
        <input
          type="text"
          value={formDefaultData?.title || ""}
          onChange={(e) => handleQuestionChange(e.target.value)}
          placeholder="Enter question"
          className="border w-full rounded px-2 py-1 mb-2"
        />
        <label className="block my-2 text-sm font-medium text-gray-900">
          Possible Answers
        </label>
        {formDefaultData?.options?.map((option, optionIndex) => (
          <div key={optionIndex} className="flex items-center mb-2">
            <input
              type="text"
              value={option.label || ""}
              onChange={(e) => handleOptionChange(optionIndex, e.target.value)}
              placeholder="Enter option"
              className="border rounded px-2 w-full py-1 mr-2"
            />
            <input
              type="checkbox"
              checked={correctAnswers.includes(optionIndex)}
              onChange={() => handleCorrectAnswerChange(optionIndex)}
              className="mr-2"
              disabled={!option.label}
            />
            {formDefaultData?.options?.length > 3 && (
              <button
                type="button"
                onClick={() => removeOption(optionIndex)}
              >
                <FontAwesomeIcon
                  icon={faRemove}
                  className="w-4 h-4 text-red-500"
                />
              </button>
            )}
          </div>
        ))}
        <button
          type="button"
          onClick={addOption}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Option
        </button>
      </div>
    </div>
  );
};

export default MultiSelectionForm;