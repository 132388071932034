import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setCropperVisibility } from "../../store/reducers/cropperSlice";
import { useDispatch, useSelector } from "react-redux";
const CropperPanel = () => {

    const { canvas } = useSelector(state => state.fabricCanvas);
    const dispatch = useDispatch()
    return (
        <div className='flex fixed z-50 top-2'>
            <div className="flex items-center gap-4 justify-end w-fit mx-auto max-w-600 z-1000 bg-gray-900 px-4 py-3  text-white rounded-md shadow-md overflow-hidden pointer-events-auto">
                <button
                    onClick={() => {
                        let objs = canvas.getObjects();
                        let cropper = objs.find(ob => ob.id === 'background');
                        console.log(cropper)
                        if (cropper) {
                            cropper.img.cropApply(true)
                        }
                        /*reRenderCanvasObjects(canvas, crop, false);*/
                        dispatch(setCropperVisibility(false));
                    }}
                    className="inline-block px-4 py-2 text-white no-underline font-inter font-normal text-base leading-5 focus:outline-none focus:border-none  bg-red-500 cursor-pointer appearance-none tap-highlight-transparent">
                    <div className='flex justify-center items-center'>
                    <span> Cancel</span>
                    <FontAwesomeIcon icon={"close"} className="size-3 text-white ml-2 transition-colors duration-200 group-hover:text-red-500" />
                    </div>
                </button>
                <button
                    onClick={() => {
                        let obj = canvas.getActiveObject();
                        if (['cropper', 'nextCropped', 'background'].includes(obj.id)) {
                            obj.img.cropApply();
                            setTimeout(() => {
                                canvas.renderAll();
                            }, 100)
                            // reRenderCanvasObjects(canvas, crop, true);
                            dispatch(setCropperVisibility(false));
                        }
                    }}

                    className="inline-block px-4 py-2 text-white no-underline font-inter font-normal text-base leading-5 focus:outline-none focus:border-none  bg-green-500 cursor-pointer appearance-none tap-highlight-transparent ">
                    <div className='flex justify-center items-center'>
                        <span> Save</span>
                        <FontAwesomeIcon icon={"check"}
                            className="size-3 text-white ml-2 transition-colors duration-200 group-hover:text-green-500" />
                    </div>
                </button>
            </div>
        </div>
    )
}
export default CropperPanel;