import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom"; // Add this import
import {
  createPreview,
  uploadJsonToS3,
  S3_BUCKET_NAME,
  saveProjectSlides
} from "../../utils/helpers";
import { useAuth } from "../../context/AuthContext";
import ScormHelper from "../../utils/scormHelper";
import { ArrowLeft, UTurnArrowLeft, Eye, FloppyDisk } from "iconoir-react"
import { displayInfoMessage } from "../../utils/helpers";


const Header = () => {
  const [uploadStatus, setUploadStatus] = useState(false);
  const { slideList } = useSelector((state) => state.slideList);
  const navigate = useNavigate();
  const { user } = useAuth();
  const location = useLocation();
  const { projectName } = location.state || {};
  const [versions, setVersions] = useState([]);
  const getProjectIdFromUrl = () => {
    const pathParts = location.pathname.split('/');
    return pathParts[pathParts.length - 1]; // Assuming projectId is the last part of the URL
  };
  const projectId = getProjectIdFromUrl();



  const handleJsonUpload = () => {
    try {
      handleVersionUpload();

      const scormHelper = new ScormHelper(slideList);

      const jsonData = scormHelper.getStringifyJSON();

      setUploadStatus(true);

      const body = {
        slides: [...jsonData],
      };

      saveProjectSlides(projectId, body, () => {
        setUploadStatus(false);
      });
    } catch (error) {
      console.error("Error saving project slides:", error);
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handlePreview = () => {
    handleJsonUpload();
    const pathParts = location.pathname.split('/');
    const projectIdFromUrl = pathParts[pathParts.length - 1]; // Assuming projectId is the last part of the URL
    createPreview(projectIdFromUrl);
    displayInfoMessage('Preview created will open in new tab', 'success');
  };

  // Upload version to S3
  const handleVersionUpload = async () => {
    const scormHelper = new ScormHelper(slideList);
    const jsonData = scormHelper.getStringifyJSON();


    // Save version locally (in memory)
    const newVersion = {
      versionNumber: versions.length + 1, // Example version number
      timestamp: Date.now(),
      data: slideList, // Use the cloned data
    };
    setVersions([...versions, newVersion]);

    // Prepare the file name and upload parameters for S3
    const fileName = `${projectId}-version-${newVersion.versionNumber}.json`;

    const params = {
      Bucket: `${S3_BUCKET_NAME}/${user?.user_id}/versions`,
      Key: fileName,
      Body: JSON.stringify(jsonData), // Use Blob for the Body
      ContentType: "application/json",
    };

    try {
      setUploadStatus(true);
      // Upload JSON to S3
      await uploadJsonToS3(fileName, params);
    } catch (error) {
      console.error("Error uploading version JSON data to S3:", error);
    } finally {
      setUploadStatus(false);
    }
  };

  return (
    <header className="h-16 flex items-center justify-between bg-white py-3 shadow-md">
      <div className="flex items-center space-x-3"> {/* Add this wrapper div */}
        <div className="border-b-[1px] border-white w-[85px] flex flex-col items-center text-xs py-3 cursor-pointer"
          onClick={() => handleNavigate("/dashboard")}
        >
          <ArrowLeft className="w-6 h-6 text-custom-topBlue" />
          <span className="text-custom-topBlue font-xxs">Dashboard</span>
        </div>
        <p className="text-grey">{projectName}</p> {/* Move this inside the new wrapper div */}
        <div className="flex align-left items-center space-x-3 border-r-[1px] border-l-[1px] border-grey">
          <UTurnArrowLeft className="w-6 h-6 text-grey rotate-90" />
          <UTurnArrowLeft className="w-6 h-6 text-grey -rotate-90" /> {/* Apply mirror rotation */}
        </div>
      </div>
      <div className="flex items-center space-x-3">
        {location.pathname !== "/dashboard" && location.pathname !== "/profile" && !location.pathname.startsWith("/edit-project/") && ( // Add this condition
          <>
            <button
              className="flex items-center rounded-md border-[1px] border-grey px-[15px] py-[6px] text-xs text-grey hover:bg-grey-200"
              onClick={handlePreview}
            >
              <Eye className="w-4 h-4 mr-2 text-grey" />
              Preview
            </button>
            <button
              className="flex items-center rounded-md bg-custom-lightBlue px-[15px] py-[6px] text-xs text-white hover:bg-grey-200"
              onClick={() => handleJsonUpload()}
              disabled={uploadStatus}
            >
              <FloppyDisk className="w-4 h-4 mr-2 text-grey" />
              {uploadStatus ? "Uploading..." : "Save"}
            </button>
          </>
        )}
        <div className="relative">
          <div className="cursor-pointer px-4"
            onClick={() => handleNavigate("/profile")}>
            <img
              src={user?.avatar ? `https://backend.ailms.co${user?.avatar}` : <div className="w-8 h-8 rounded-full bg-gray-300"></div>}
              alt="Profile"
              className="w-8 h-8 rounded-full border-[1px] border-grey"
            />
          </div>

        </div>
      </div>
    </header>
  );
};

export default Header;
