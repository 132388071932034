// eslint-disable-next-line
import { fabric } from "fabric";
//import { v4 as uuidv4 } from "uuid";


export const getScormIndexString = (
    title = "Reveal",
    slideListData,
    isPreview,
    fontsUrl
) => {
    const slideDataScript = `
        <script>
            const slideData = ${JSON.stringify(slideListData)};
        </script>
    `;

    return `
            <!doctype html>
            <html lang="en">
            <head>
                <meta charset="utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
                <title>${title}</title>
                <link rel="stylesheet" href="assets/css/reset.min.css">
                <link rel="stylesheet" href="assets/css/reveal.min.css">
                <link rel="stylesheet" href="assets/css/black.min.css">
                <link rel="stylesheet" href="${fontsUrl}">
                
                <!-- Necessary scripts for fabric -->
                <script src="https://cdnjs.cloudflare.com/ajax/libs/fabric.js/4.5.0/fabric.min.js"></script>
                <!-- Link to reveal.js CSS -->
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/reveal.js@4.2.0/dist/reveal.min.css">
                <!-- Link SCROM API when on preview -->
                <script src="
                https://cdn.jsdelivr.net/npm/pipwerks-scorm-api-wrapper@0.1.2/index.min.js
                "></script>

                <!-- Theme for the presentation -->
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/reveal.js@4.2.0/dist/theme/white.min.css">

                <!-- Tailwind CSS -->
                <script src="https://cdn.tailwindcss.com"></script>

                <!-- Necessary scripts -->

                <script src="https://cdn.jsdelivr.net/npm/reveal.js@5.1.0/dist/reveal.min.js"></script>
                <script src="https://cdn.jsdelivr.net/npm/reveal.js@4.2.0/dist/notes.min.js"></script>
                <style>
                    #slides-container{
                        pointer-events: auto !important;
                    }
                    input:focus-visible {
                        outline: none;
                    }
                    .reveal section canvas {
                        border: 1px solid #000;  /* Customize border width and color */
                        border-radius: 5px;      /* Optional rounded corners */
                    }
                </style>
            </head>
            <body>
            <div class="reveal">
                <div class="slides" id="slides-container">
                    <!-- Dynamically generated sections -->
                </div>
            </div>
            
            ${slideDataScript}
            
            <script>
            // Define the scaling factor from the editor
            const editorScaleFactor = 1; // Example value; dynamically fetch if necessary

            // Define reference boundingRect dimensions after initialization of the first slide
            let referenceBoundingRect = null;

            slideData.forEach(slide => {
                const boundingRect = slide.objects.find(obj => obj.id === 'boundingRect');

                if (boundingRect) {
                    const { fabricCanvas, section } = createFabricCanvas(slide.id, boundingRect);

                    if (!referenceBoundingRect) {
                        referenceBoundingRect = { width: boundingRect.width, height: boundingRect.height };
                    }

                    const scaleX = (referenceBoundingRect.width / boundingRect.width) * editorScaleFactor;
                    const scaleY = (referenceBoundingRect.height / boundingRect.height) * editorScaleFactor;

                    // Load and scale each object
                    const parsedCanvasJSON = slide.objects.filter(obj => obj.id !== 'boundingRect');
                    parsedCanvasJSON.forEach(object => {
                        object.left *= scaleX;
                        object.top *= scaleY;
                        object.width *= scaleX;
                        object.height *= scaleY;
                        object.selectable = false;
                        object.evented = false;
                        if (object.scaleX) object.scaleX *= scaleX;
                        if (object.scaleY) object.scaleY *= scaleY;

                        if (object.type === 'textbox') {
                            const textbox = new fabric.Textbox(object.text, {
                                fontFamily: object.fontFamily,
                                ...object
                            });
                            textbox.set({ selectable: false, evented: false });
                            fabricCanvas.add(textbox);
                        } else if (object.type === 'image') {
                            const imagePath = object.src;
                            fabric.Image.fromURL(imagePath, (fabricImageInstance) => {
                                fabricImageInstance.set({
                                    top: object.top,
                                    left: object.left,
                                    scaleX: object.scaleX,
                                    scaleY: object.scaleY,
                                    angle: object.angle,
                                    originX: object.originX,
                                    originY: object.originY,
                                    selectable: false,
                                    evented: false
                                });
                                fabricCanvas.add(fabricImageInstance);
                            });
                        } else if (object.type === 'result') {
                            const imagePath = object.src;
                            fabric.Image.fromURL(imagePath, (fabricImageInstance) => {
                                fabricImageInstance.set({
                                    top: 0,
                                    left: 0,
                                    scaleX: object.scaleX,
                                    scaleY: object.scaleY,
                                    angle: object.angle,
                                    originX: object.originX,
                                    originY: object.originY,
                                    selectable: false,
                                    evented: false
                                });
                                fabricCanvas.add(fabricImageInstance);
                            });
                        } else if (object.type === 'polygon') {
                            const polygon = new fabric.Polygon(object.points, {...object});
                            // setAnimation(polygon, fabricCanvas);
                            fabricCanvas.add(polygon);
                        } else if (object.type === 'line') {
                            const line = new fabric.Line(object.points, {...object});
                            // setAnimation(line, fabricCanvas);
                            fabricCanvas.add(line);
                        } else if (object.type === 'circle') {
                            const circle = new fabric.Circle({...object});
                            // setAnimation(circle, fabricCanvas);
                            fabricCanvas.add(circle);
                        } if(object.type === 'quiz') {
                            const formData = object.formData || {};
                            // Create inner div
                            const containerDiv = document.createElement('div');
                            containerDiv.classList.add('mx-auto', 'bg-white', 'p-4', 'rounded-lg', 'shadow-md', 'w-full', 'h-full', 
                                'flex', 'justify-center', 'items-center', 'flex-col', 'space-y-2');
                            containerDiv.style.position = 'absolute';
                            containerDiv.style.left = 0;
                            containerDiv.style.top = 0;
                            containerDiv.style.zIndex = 99;

                            // Create question heading
                            const questionHeading = document.createElement('h6');
                            questionHeading.classList.add('text-[16px]', object.questionType === "true-false" ? 'text-center' : 'text-left', 'font-semibold', 'mb-4', '!text-black');
                            questionHeading.textContent = formData.question || formData.title;
                            containerDiv.appendChild(questionHeading); 

                            switch (object.questionType) {
                                case "true-false":
                                    // Create True/False buttons div
                                    const buttonsDiv = document.createElement('div');
                                    buttonsDiv.classList.add('flex', 'justify-center', 'mb-6');
                                    
                                    // Create True button
                                    const trueButton = document.createElement('button');
                                    trueButton.id = 'trueButton';
                                    trueButton.classList.add('px-4', 'py-2', 'mr-4', 'bg-blue-500', 'text-[16px]', 'text-white', 'rounded-lg', 'shadow-md', 'hover:bg-blue-600', 'focus:outline-none', 'focus:bg-blue-600');
                                    trueButton.textContent = formData.trueBtnText || 'True';
                                    
                                    // Create False button
                                    const falseButton = document.createElement('button');
                                    falseButton.id = 'falseButton';
                                    falseButton.classList.add('px-4', 'py-2', 'bg-red-500', 'text-white', 'text-[16px]', 'rounded-lg', 'shadow-md', 'hover:bg-red-600', 'focus:outline-none', 'focus:bg-red-600');
                                    falseButton.textContent = formData.falseBtnText || 'False';
                                    
                                    // Append buttons to buttons div
                                    buttonsDiv.appendChild(trueButton);
                                    buttonsDiv.appendChild(falseButton);
                                    
                                    // Create Feedback Answers div
                                    const feedbackDiv = document.createElement('div');
                                    feedbackDiv.classList.add('text-center');
                                    
                                    // Create Correct Answer Feedback div
                                    const correctFeedbackDiv = document.createElement('div');
                                    correctFeedbackDiv.id = 'correctFeedback';
                                    correctFeedbackDiv.classList.add('hidden', 'mb-4');
                                    
                                    // Create Correct Answer Feedback text
                                    const correctFeedbackText = document.createElement('p');
                                    correctFeedbackText.classList.add('text-green-600', 'font-semibold', 'text-sm', '!my-2');
                                    correctFeedbackText.textContent = 'Correct!';
                                    
                                    // Create Correct Answer Feedback message
                                    const correctFeedbackMessage = document.createElement('p');
                                    correctFeedbackMessage.classList.add('text-sm', 'text-gray-600', '!my-2');
                                    correctFeedbackMessage.textContent = formData.trueAnsPropmt || '';
                                    
                                    // Append text and message to Correct Answer Feedback div
                                    correctFeedbackDiv.appendChild(correctFeedbackText);
                                    correctFeedbackDiv.appendChild(correctFeedbackMessage);
                                    
                                    // Create Incorrect Answer Feedback div
                                    const incorrectFeedbackDiv = document.createElement('div');
                                    incorrectFeedbackDiv.id = 'incorrectFeedback';
                                    incorrectFeedbackDiv.classList.add('hidden');
                                    
                                    // Create Incorrect Answer Feedback text
                                    const incorrectFeedbackText = document.createElement('p');
                                    incorrectFeedbackText.classList.add('text-red-600', 'font-semibold', 'text-sm', '!my-2');
                                    incorrectFeedbackText.textContent = 'Incorrect!';
                                    
                                    // Create Incorrect Answer Feedback message
                                    const incorrectFeedbackMessage = document.createElement('p');
                                    incorrectFeedbackMessage.classList.add('text-sm', 'text-gray-600', '!my-2');
                                    incorrectFeedbackMessage.textContent = formData.falseAnsPropmt || '';
                                    
                                    // Append text and message to Incorrect Answer Feedback div
                                    incorrectFeedbackDiv.appendChild(incorrectFeedbackText);
                                    incorrectFeedbackDiv.appendChild(incorrectFeedbackMessage);
                                    
                                    // Append Correct and Incorrect Feedback divs to Feedback Answers div
                                    feedbackDiv.appendChild(correctFeedbackDiv);
                                    feedbackDiv.appendChild(incorrectFeedbackDiv);
                                    
                                    // Add click event listeners to the true/false buttons
                                    trueButton.addEventListener('click', () => {
                                      // Show correct feedback and hide incorrect feedback
                                      correctFeedbackDiv.classList.remove('hidden');
                                      incorrectFeedbackDiv.classList.add('hidden');
                                    });
                                    
                                    falseButton.addEventListener('click', () => {
                                      // Show incorrect feedback and hide correct feedback
                                      correctFeedbackDiv.classList.add('hidden');
                                      incorrectFeedbackDiv.classList.remove('hidden');
                                    });
                                    // Append all elements to inner div
                                    containerDiv.appendChild(buttonsDiv);
                                    containerDiv.appendChild(feedbackDiv);
                                    break;

                                case "single-selection": {
                                    // Check if options exist
                                    const options = formData.options.filter((i) => i.label) || [];
                                    if (options.length) {
                                    // Create the form element
                                    const form = document.createElement("form");
                                    
                                    // Create the div for options
                                    const optionsDiv = document.createElement("div");
                                    optionsDiv.className = "space-y-2";
                                    let answer = '';
                                    let result = { msg : '', type: '' };
                                                                    
                                    // Create the submit button
                                    const submitButton = document.createElement("button");
                                    submitButton.type = "submit";
                                    submitButton.disabled = answer === '';
                                    submitButton.classList.add('px-4', 'py-2', 'my-3', 'bg-blue-500', 'float-left', 'text-[16px]', 'text-white', 'rounded-lg', 'shadow-md', 'hover:bg-blue-600', 'focus:outline-none', 'focus:bg-blue-600');
                                    submitButton.textContent = "Submit";

                                    // Loop through options and create radio buttons
                                        options.forEach((option, index) => {
                                        const optionDiv = document.createElement("div");
                                        optionDiv.className = "flex items-center";
                                    
                                        const radioInput = document.createElement("input");
                                        radioInput.type = "radio";
                                        radioInput.id = 'option-' + index;
                                        radioInput.name = "option";
                                        radioInput.value = index;
                                        radioInput.className = "mr-2 text-[16px]";
                                        radioInput.addEventListener("change", function() {
                                            answer = index;
                                            radioInput.checked = true;
                                            submitButton.disabled = false;
                                        });
                                    
                                        const label = document.createElement("label");
                                        label.htmlFor =  'option-' + index;
                                        label.className = "select-none text-[16px] text-black text-left w-full";
                                        label.textContent = option.label;
                                    
                                        optionDiv.appendChild(radioInput);
                                        optionDiv.appendChild(label);
                                    
                                        optionsDiv.appendChild(optionDiv);
                                    });
                                    
                                    // Append options div to form
                                    form.appendChild(optionsDiv);

                                    submitButton.addEventListener("click", function(event) {
                                        event.preventDefault();
                                        if(result.type){
                                            resultParagraph.classList.remove(('text-' + result.type + '-600'));
                                        }
                                        if(answer === formData.correctAnswer){
                                            result = { msg : 'Correct Answer!', type: 'green' }
                                        }else {
                                            result = { msg : 'Incorrect Answer!', type: 'red' }
                                        }
                                        if (result.msg) {
                                            // Create paragraph element for result message
                                            resultParagraph.textContent = result.msg;
                                            resultParagraph.classList.add(('text-' + result.type + '-600'));
                                        }
                                    });

                                    // Append submit button to form
                                    form.appendChild(submitButton);
                                                                    
                                    // Append form to main div
                                    containerDiv.appendChild(form);

                                    const resultParagraph = document.createElement("p");
                                    resultParagraph.classList.add('mt-4', 'font-semibold', 'text-[16px]');
                                    // Append result paragraph to main div
                                    containerDiv.appendChild(resultParagraph);

                                    }
                                    break;
                                }
                                case "multiple-selection":{
                                    // Check if options exist
                                    const options = formData.options.filter((i) => i.label) || [];
                                    if (options.length) {
                                    // Create the form element
                                    const form = document.createElement("form");

                                    // Create the div for options
                                    const optionsDiv = document.createElement("div");
                                    optionsDiv.className = "space-y-2";
                                    let answers = []; // Changed to array for multiple selections
                                    let result = { msg: '', type: '' };

                                    // Create the submit button
                                    const submitButton = document.createElement("button");
                                    submitButton.type = "submit";
                                    submitButton.disabled = answers.length === 0; // Check if answers array is empty
                                    submitButton.classList.add('px-4', 'py-2', 'my-3', 'bg-blue-500', 'float-left', 'text-[16px]', 'text-white', 'rounded-lg', 'shadow-md', 'hover:bg-blue-600', 'focus:outline-none', 'focus:bg-blue-600');
                                    submitButton.textContent = "Submit";

                                    // Loop through options and create checkboxes
                                    options.forEach((option, index) => {
                                        const optionDiv = document.createElement("div");
                                        optionDiv.className = "flex items-center";

                                        const checkboxInput = document.createElement("input");
                                        checkboxInput.type = "checkbox"; // Change type to checkbox
                                        checkboxInput.id = 'option-' + index;
                                        checkboxInput.name = "option";
                                        checkboxInput.value = index;
                                        checkboxInput.className = "mr-2 text-[16px]";
                                        checkboxInput.addEventListener("change", function () {
                                        if (checkboxInput.checked) {
                                            answers.push(index); // Add selected option to answers array
                                        } else {
                                            const indexToRemove = answers.indexOf(index);
                                            if (indexToRemove !== -1) {
                                            answers.splice(indexToRemove, 1); // Remove deselected option from answers array
                                            }
                                        }
                                        submitButton.disabled = answers.length === 0; // Enable/disable submit button based on selections
                                        });

                                        const label = document.createElement("label");
                                        label.htmlFor = 'option-' + index;
                                        label.className = "select-none text-[16px] text-black text-left w-full";
                                        label.textContent = option.label;

                                        optionDiv.appendChild(checkboxInput);
                                        optionDiv.appendChild(label);

                                        optionsDiv.appendChild(optionDiv);
                                    });

                                    // Append options div to form
                                    form.appendChild(optionsDiv);

                                    submitButton.addEventListener("click", function (event) {
                                        event.preventDefault();
                                        if (result.type) {
                                        resultParagraph.classList.remove(('text-' + result.type + '-600'));
                                        }
                                        const isCorrect = answers.every(ans => formData.correctAnswer.includes(ans)); // Check if all selected answers are correct
                                        result = { msg: isCorrect ? 'Correct Answer!' : 'Incorrect Answer!', type: isCorrect ? 'green' : 'red' };
                                        if (result.msg) {
                                        // Create paragraph element for result message
                                        resultParagraph.textContent = result.msg;
                                        resultParagraph.classList.add(('text-' + result.type + '-600'));
                                        }
                                    });

                                    // Append submit button to form
                                    form.appendChild(submitButton);

                                    // Append form to main div
                                    containerDiv.appendChild(form);

                                    const resultParagraph = document.createElement("p");
                                    resultParagraph.classList.add('mt-4', 'font-semibold', 'text-[16px]');
                                    // Append result paragraph to main div
                                    containerDiv.appendChild(resultParagraph);
                                    }

                                    break;
                                }
                                default:
                                    break;
                            }
                           
                            
                            // setAnimation(containerDiv, null, object?.customAnimation);
                            // Append container div to document body
                            section.appendChild(containerDiv);
                        } else {
                            console.warn("Unsupported shape type: " + object.type);
                        }
                    });
                }
            });

            // Function to create Fabric canvas for a given slide using boundingRect dimensions
            function createFabricCanvas(slideId, boundingRect) {
                const canvasId = 'canvas-' + slideId;
                const section = document.createElement('section');
                section.setAttribute('data-id', slideId);

                const canvas = document.createElement('canvas');
                canvas.id = canvasId;
                section.appendChild(canvas);

                document.getElementById('slides-container').appendChild(section);

                if (!referenceBoundingRect) {
                    referenceBoundingRect = { width: boundingRect.width, height: boundingRect.height };
                }

                return {
                    fabricCanvas: new fabric.Canvas(canvasId, {
                        backgroundColor: 'white',
                        height: boundingRect.height * editorScaleFactor,
                        width: boundingRect.width * editorScaleFactor,
                        selection: false,
                        isDrawingMode: false,
                        evented: false,
                    }),
                    section
                };
            }

            function initializeReveal() {
                if (typeof Reveal !== 'undefined') {
                    if (referenceBoundingRect) {
                        Reveal.initialize({
                            hash: true,
                            width: referenceBoundingRect.width * editorScaleFactor,
                            height: referenceBoundingRect.height * editorScaleFactor,
                            minScale: 1,
                            maxScale: 1,
                            plugins: []
                        });
                        console.log('Reveal.js initialized with dimensions:', referenceBoundingRect.width, referenceBoundingRect.height);
                    } else {
                        console.warn('Reference boundingRect dimensions not found.');
                    }
                } else {
                    console.warn('Reveal.js is not loaded');
                }
            }

            window.addEventListener('load', initializeReveal);
        </script>
<script>
                    function formatSessionTime(seconds) {
                        const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
                        const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
                        const secs = (seconds % 60).toString().padStart(2, '0');
                        return hours + ":" + minutes + ":" + secs; // Replaced template literal
                    }

                    let sessionStartTime = Date.now();
                    
                    Reveal.addEventListener('slidechanged', function(event) {
                        const currentSlideIndex = event.indexh;
                        const totalSlides = Reveal.getTotalSlides();
                        const lessonStatus = currentSlideIndex === totalSlides - 1 ? 'completed' : 'in-progress';
                        const successStatus = lessonStatus === 'completed' ? 'passed' : 'unknown';

                        const sessionEndTime = Date.now();
                        const sessionTime = Math.floor((sessionEndTime - sessionStartTime) / 1000);
                        sessionStartTime = sessionEndTime;

                        window.parent.postMessage({
                        type: 'scormUpdate',
                        location: currentSlideIndex,
                        status: lessonStatus,
                        successStatus: successStatus,
                        sessionTime: formatSessionTime(sessionTime)
                        }, '*');

                        console.log('Sent SCORM data to parent window for slide ' + currentSlideIndex);
                    });

                    function submitQuiz(answer) {
                        window.parent.postMessage({
                        type: 'scormInteraction',
                        interactionId: 'quiz1',
                        learnerResponse: answer,
                        result: answer === 'correct_answer' ? 'correct' : 'incorrect'
                        }, '*');
                    }

                    // Handle button click example
                    function handleButtonClick(buttonId) {
                        window.parent.postMessage({
                        type: 'scormInteraction',
                        interactionId: buttonId,
                        learnerResponse: 'clicked',
                        result: 'n/a'
                        }, '*');
                    }

                    // Add onChange event listener to the input
                    input.addEventListener('input', function(event) {
                        const inputValue = event.target.value;

                        // Call the handleInputChange function defined in the external JS
                        window.parent.postMessage({
                            type: 'inputChange',
                            interactionId: object.id || 'input_' + uuidv4(), // Fallback if no id
                            value: inputValue
                        }, '*'); // Use '*' to allow cross-origin communication if needed

                    });

                        const quizSubmitButton = document.getElementById('quizSubmitButton');
                        if (quizSubmitButton) {
                        quizSubmitButton.addEventListener('click', function() {
                            const answer = document.querySelector('input[name="quiz"]:checked').value;
                            submitQuiz(answer, 'quiz1', 'correct_answer');
                        });
                        }
                    </script>



            </body>
            </html>
`;
};


