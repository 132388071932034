import React, { useMemo } from "react";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SingleSelectionForm = ({ data, setData }) => {
  const addOption = () => {
    setData({
      ...data,
      options: [...(data.options ?? []), { label: "" }]
    });
  };

  const removeOption = (optionIndex) => {
    const newOptions = data.options.filter((_, index) => index !== optionIndex);
    setData({
      ...data,
      options: newOptions,
      correctAnswer: data.correctAnswer === optionIndex 
        ? null 
        : data.correctAnswer > optionIndex
        ? data.correctAnswer - 1
        : data.correctAnswer
    });
  };

  const handleQuestionChange = (value) => {
    setData({
      ...data,
      title: value
    });
  };

  const handleOptionChange = (optionIndex, value) => {
    const newOptions = data.options.map((option, index) => 
      index === optionIndex ? { ...option, label: value } : option
    );
    
    setData({
      ...data,
      options: newOptions,
      correctAnswer: !value && optionIndex === data.correctAnswer ? null : data.correctAnswer
    });
  };

  const handleCorrectAnswerChange = (optionIndex) => {
    setData({
      ...data,
      correctAnswer: optionIndex
    });
  };

  const formDefaultData = useMemo(() => data, [data]);

  return (
    <div>
      <div className="my-4">
        <label
          htmlFor="question"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Enter Your Question
        </label>
        <input
          type="text"
          value={formDefaultData?.title || ""}
          onChange={(e) => handleQuestionChange(e.target.value)}
          placeholder="Enter question"
          className="border w-full rounded px-2 py-1 mb-2"
        />
        <label className="block my-2 text-sm font-medium text-gray-900">
          Possible Answers
        </label>
        {formDefaultData?.options?.map((option, optionIndex) => (
          <div key={optionIndex} className="flex items-center mb-2">
            <input
              type="text"
              value={option.label || ""}
              onChange={(e) => handleOptionChange(optionIndex, e.target.value)}
              placeholder="Enter option"
              className="border rounded px-2 w-full py-1 mr-2"
            />
            <input
              type="radio"
              name="correct-answer"
              checked={formDefaultData.correctAnswer === optionIndex}
              onChange={() => handleCorrectAnswerChange(optionIndex)}
              className="mr-2"
              disabled={!option.label}
            />
            {formDefaultData?.options?.length > 3 && (
              <button 
                type="button"
                onClick={() => removeOption(optionIndex)}
              >
                <FontAwesomeIcon
                  icon={faRemove}
                  className="w-4 h-4 text-red-500"
                />
              </button>
            )}
          </div>
        ))}
        <button
          type="button"
          onClick={addOption}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Option
        </button>
      </div>
    </div>
  );
};

export default SingleSelectionForm;